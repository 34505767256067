<template>
  <div>
    <h1 class="heading-txt mb-3">Update Profile</h1>
    <div class="row">
      <div class="col-md-5">
        <form role="form" @submit.prevent="updateProfile">
          <div class="card siteCard mt-3">
            <div class="p-3">
              <div class="mb-4">
                <label for="imgUpload" class="img-upload">
                  <vs-avatar size="150px" :src="profileImage" />
                  <span class="add-icon bg-primary">
                    <PlusIcon size="20" />
                  </span>
                </label>

                <input type="file" name="imgUpload" id="imgUpload" accept=".png, .jpg, .jpeg" class="d-none"
                  @change="onFileChange" />
              </div>
              <div class="form-group">
                <label class="text-dark" to="email">Email address</label>
                <input v-model="userUpdate.email" type="email" disabled name="email" tabindex="1" required id="email"
                  class="form-control" />
              </div>

              <div class="form-group">
                <label class="text-dark" to="name">Full name</label>
                <input v-model="userUpdate.name" name="name" required tabindex="1" id="name" class="form-control" />
              </div>

              <button type="submit" :disabled="loading" class="btn btn-primary btn-site px-5 mt-4">
                <span> Save Changes</span>
                <BtnLoading v-if="loading" class="d-inline-block" height="18" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";
import { PlusIcon } from "vue-feather-icons";
export default {
  data() {
    return {
      loading: false,
      errors: {},
      newFile: null,
      userUpdate: {
        name: "",
        email: "",
      },
      profileImage: "",
    };
  },
  components: {
    BtnLoading, PlusIcon
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
  },
  mounted() {
    this.setUser();
  },

  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.profileImage = e.target.result;
      };
      reader.readAsDataURL(file);
      this.newFile = file;
    },
    setUser() {
      this.userUpdate.name = this.user.name;
      this.userUpdate.email = this.user.email;
      this.profileImage = this.user.profile_photo_url;
    },
    resetUser() {
      let fetch = {
        path: `/profile`,
      };

      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          let { user } = resp.data.data;
          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("user", user);
          location.reload();
        })
        .catch((err) => { });
    },
    updateProfile() {
      this.loading = true;
      let data = null;

      if (this.newFile) {
        const formData = new FormData();
        formData.append("photo", this.newFile);
        formData.append("name", this.userUpdate.name);
        formData.append("email", this.userUpdate.email);
        data = formData;
      } else {
        data = this.userUpdate;
      }

      let fetch = {
        path: `/profile/update`,
        data,
      };

      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;

          this.loading = false;
          this.resetUser();
          this.$toast.success(
            "Profile Update",
            "Your profile has been updated successfully",
            this.$toastPosition
          );
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            const { errors } = err.response.data;
            this.errors = errors;
            this.$toast.info(
              "Profile Update",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Profile Update",
              "Unable to update profile, please try again later",
              this.$toastPosition
            );
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.heading-txt {
  font-size: 3em;
  line-height: 1;
  font-weight: 700;
  color: #000;
}

.sect-data {
  border-bottom: 1px solid gainsboro;
  margin-bottom: 13px;
}

.active-plan {
  color: #000;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.img-upload {
  position: relative;
  cursor: pointer;
  display: inline-block;

  .add-icon {
    position: absolute;
    right: 10px;
    top: 0px;
    top: 10px;
    outline: 0;
    color: white;
    display: flex;
    padding: 7px;
    border-radius: 27px;
  }
}
</style>
